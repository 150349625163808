@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  h1 {
    @apply text-6xl font-bold;
    font-family: "Poppins";
  }
}

.checkbox-wrapper-12 {
  position: relative;
}

.checkbox-wrapper-12 > svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper-12 * {
  box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper-12 .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked + label {
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
  40% {
    background: #FF135A;
    box-shadow: 0 -18px 0 -8px #FF135A, 16px -8px 0 -8px #FF135A, 16px 8px 0 -8px #FF135A, 0 18px 0 -8px #FF135A, -16px 8px 0 -8px #FF135A, -16px -8px 0 -8px #FF135A;
  }

  100% {
    background: #FF135A;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: #FF135A;
    box-shadow: 0 -18px 0 -8px #FF135A, 16px -8px 0 -8px #FF135A, 16px 8px 0 -8px #FF135A, 0 18px 0 -8px #FF135A, -16px 8px 0 -8px #FF135A, -16px -8px 0 -8px #FF135A;
  }

  100% {
    background: #FF135A;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: #FF135A;
    box-shadow: 0 -18px 0 -8px #FF135A, 16px -8px 0 -8px #FF135A, 16px 8px 0 -8px #FF135A, 0 18px 0 -8px #FF135A, -16px 8px 0 -8px #FF135A, -16px -8px 0 -8px #FF135A;
  }

  100% {
    background: #FF135A;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #FF135A;
    box-shadow: 0 -18px 0 -8px #FF135A, 16px -8px 0 -8px #FF135A, 16px 8px 0 -8px #FF135A, 0 18px 0 -8px #FF135A, -16px 8px 0 -8px #FF135A, -16px -8px 0 -8px #FF135A;
  }

  100% {
    background: #FF135A;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

/* .get-started-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: greenyellow;
  box-shadow: 0 0 0 2px greenyellow;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.get-started-button svg {
  position: absolute;
  width: 24px;
  fill: greenyellow;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.get-started-button .arr-1 {
  right: 16px;
}

.get-started-button .arr-2 {
  left: -25%;
}

.get-started-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: greenyellow;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.get-started-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.get-started-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}

.get-started-button:hover .arr-1 {
  right: -25%;
}

.get-started-button:hover .arr-2 {
  left: 16px;
}

.get-started-button:hover .text {
  transform: translateX(12px);
}

.get-started-button:hover svg {
  fill: #212121;
}

.get-started-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px greenyellow;
}

.get-started-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
} */

.get-started-button .btn-conteiner {
  display: flex;
  justify-content: center;
  --color-text: #ffffff;
  --color-background: #ff135a;
  --color-outline: #ff145b80;
  --color-shadow: #00000080;
}

.get-started-button .btn-content {
  display: flex;
  align-items: center;
  padding: 5px 30px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: var(--color-text);
  background: var(--color-background);
  transition: 1s;
  border-radius: 100px;
  box-shadow: 0 0 0.2em 0 var(--color-background);
}

.get-started-button .btn-content:hover, .btn-content:focus {
  transition: 0.5s;
  -webkit-animation: btn-content 1s;
  animation: btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 var(--color-background);
}

.get-started-button .btn-content .icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.get-started-button .btn-content:hover .icon-arrow {
  transition: 0.5s;
  margin-right: 25px;
}

.get-started-button .icon-arrow {
  width: 20px;
  margin-left: 15px;
  position: relative;
  top: 6%;
}
  
/* SVG */
.get-started-button #arrow-icon-one {
  transition: 0.4s;
  transform: translateX(-60%);
}

.get-started-button #arrow-icon-two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.get-started-button .btn-content:hover #arrow-icon-three {
  animation: color_anim 1s infinite 0.2s;
}

.get-started-button .btn-content:hover #arrow-icon-one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.get-started-button .btn-content:hover #arrow-icon-two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */
@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: var(--color-background);
  }

  100% {
    fill: white;
  }
}

/* Button animations */
@-webkit-keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

@keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

/* .input-group {
  position: relative;
 }
 
 .input {
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: #000;
  transition: border 150ms cubic-bezier(0.4,0,0.2,1);
 }
 
 .user-label {
  position: absolute;
  left: 15px;
  color: #000;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4,0,0.2,1);
 }
 
 .input:focus, input:valid {
  outline: none;
  border: 1.5px solid #1a73e8;
 }
 
 .input:focus ~ label, input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #fff;
  padding: 0 .2em;
  color: #2196f3;
 } */

 /* Radio Button */

 .radio_input[type='radio']:checked,
 .radio_input[type='radio']:not(:checked) {
   position: absolute;
   left: -9999px;
   display: none;
 }
 
 .radio_input[type='radio']:checked + label,
 .radio_input[type='radio']:not(:checked) + label {
   position: relative;
   padding-left: 28px;
   cursor: pointer;
   line-height: 18px;
 }
 
 .radio_input[type='radio']:checked + label:before,
 .radio_input[type='radio']:not(:checked) + label:before {
   content: '';
   position: absolute;
   left: 0;
   top: 0;
   width: 18px;
   height: 18px;
   border: 1px solid rgb(var(--color-border-base));
   border-radius: 100%;
   background-color: #ffffff;
 }
 
 .radio_input[type='radio']:checked + label:before {
   border-color: rgb(var(--color-accent));
 }
 
 .radio_input[type='radio']:checked + label:after,
 .radio_input[type='radio']:not(:checked) + label:after {
   content: '';
   width: 10px;
   height: 10px;
   background: rgb(var(--color-accent));
   position: absolute;
   top: 4px;
   left: 4px;
   border-radius: 100%;
   -webkit-transition: all 0.2s ease;
   transition: all 0.2s ease;
 }
 
 .radio_input[type='radio']:not(:checked) + label:after {
   opacity: 0;
   -webkit-transform: scale(0);
   transform: scale(0);
 }
 
 .radio_input[type='radio']:checked + label:after {
   opacity: 1;
   -webkit-transform: scale(1);
   transform: scale(1);
 }
 


